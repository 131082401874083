import type { SearchInputProps as SearchAutocompleteProps } from './SearchAutocomplete'
import React, { startTransition, useCallback } from 'react'
import { useRouter } from 'next-nprogress-bar'
import { useIsBlocked } from '../NavigationBlock'
import SearchAutocomplete from './SearchAutocomplete'
import { unsavedChangesMessage } from '@/lib/utils/form'

type SearchInputProps = Pick<SearchAutocompleteProps<false, false>, 'autoFocus' | 'searchType'>

const SearchInput = (searchProps: SearchInputProps): JSX.Element => {
    const router = useRouter()
    const isBlocked = useIsBlocked()
    const handleChange = useCallback<SearchAutocompleteProps<false, false>['onChange']>(
        (_e, option, reason) => {
            // prevent default navigation for dirty forms
            if (_e && reason === 'selectOption') {
                _e.preventDefault()
                // eslint-disable-next-line no-alert
                if (isBlocked && !window.confirm(unsavedChangesMessage)) {
                    return
                }
            }
            startTransition(() => {
                if (!option || typeof option === 'string' || reason !== 'selectOption') return
                if (option.type === 'agency') {
                    void router.push(`/agencies/${option.urlSlug}/feed`)
                } else if (option.type === 'staff') {
                    void router.push(`/staff/${option.urlSlug}/feed`)
                } else void router.push(`/${option.type}s/${option.urlSlug}/feed`)
            })
        },
        [router, isBlocked]
    )
    return <SearchAutocomplete {...searchProps} multiple={false} onChange={handleChange} />
}

export default SearchInput
