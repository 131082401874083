import type { ToastContextInterface } from '../contexts/ToastContext'

export default (
    e: Error,
    toastContext: ToastContextInterface | null,
    setSubmitting?: (isSubmitting: boolean) => void
): void => {
    toastContext?.addToast({
        severity: 'error',
        message: e.message,
    })
    setSubmitting?.(false)
}
