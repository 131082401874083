import type { Except } from 'type-fest'
import type { UseQueryResult } from '@tanstack/react-query'
import type {
    AutocompleteValue,
    AutocompleteChangeReason,
    AutocompleteChangeDetails,
    TypographyProps,
} from '@mui/material'
import React, { useState, useMemo, useCallback, useRef } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import ListSubheader from '@mui/material/ListSubheader'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import ClearIcon from '@mui/icons-material/Clear'
import GroupsIcon from '@mui/icons-material/Groups'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import Chip from '@mui/material/Chip'
import { capitalize } from '../../lib/utils/formatters'
import { useSearch } from '../../lib/hooks'
import useDebounce from '../../lib/hooks/useDebounce'
import ImageWithFallback from '../ImageWithFallback'

const useSearchResult = (
    debouncedSearchQuery: string,
    searchResultType?: DTO.SearchResultType | DTO.SearchResultType[]
): [UseQueryResult<DTO.SearchResult[]>, DTO.SearchResultType[]] => {
    const resultTypes = searchResultType && (Array.isArray(searchResultType) ? searchResultType : [searchResultType])
    const resultSearch = useSearch(debouncedSearchQuery, resultTypes)
    return [resultSearch, resultTypes || []]
}

export type SearchResultType<N extends boolean> = N extends true
    ? DTO.CreateSearchResult & { disabled?: boolean }
    : DTO.SearchResult & { disabled?: boolean }
type SearchInputValue<M extends boolean, N extends boolean> = AutocompleteValue<SearchResultType<N>, M, undefined, true>
export type SearchInputProps<M extends boolean, N extends boolean> = {
    onChange: (
        event: React.SyntheticEvent | null,
        value: SearchInputValue<M, N>,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<SearchResultType<N>>
    ) => void
    multiple: M
    label?: string
    placeholder?: string
    autoFocus?: boolean
    error?: boolean | undefined
    errorText?: string | null
    required?: boolean
    fullWidth?: boolean
    value?: SearchInputValue<M, N>
    searchType?: DTO.SearchResultType | DTO.SearchResultType[]
    blurOnSelect?: boolean
    disabled?: boolean
    disabledSlugs?: string[]
}

type SearchResultSecondaryTypographyProps = Except<TypographyProps, 'color' | 'variant' | 'noWrap'> & {
    text: string | null | undefined
    characterLimit: number
    ellipses?: boolean
}
const SearchResultTypographySecondary = ({
    text,
    characterLimit,
    ellipses,
    lineHeight = 1.4,
    padding = 0,
    margin = 0,
    ...rest
}: SearchResultSecondaryTypographyProps): JSX.Element | null =>
    text ? (
        <Typography
            lineHeight={lineHeight}
            padding={padding}
            margin={margin}
            color="text.secondary"
            variant="subtitle2"
            noWrap
            {...rest}
        >
            {!ellipses ? (
                text
            ) : (
                <Box
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: `${Math.ceil(characterLimit * 7.27)}px`,
                        display: 'inline-block',
                        height: '100%',
                    }}
                >
                    {text}
                </Box>
            )}
        </Typography>
    ) : null

export default function SearchInput<Multiple extends boolean = false, AllowCreation extends boolean = false>({
    onChange,
    multiple,
    autoFocus,
    error,
    required,
    fullWidth,
    value,
    label,
    placeholder = 'Search',
    searchType,
    blurOnSelect = true,
    disabled,
    disabledSlugs,
}: SearchInputProps<Multiple, AllowCreation>): JSX.Element {
    const [query, setQuery] = useState('')
    const [active, setActive] = useState(false)
    const debouncedSearchQuery = useDebounce(query)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [{ data: searchResults, isLoading, isInitialLoading }, types] = useSearchResult(
        debouncedSearchQuery || '',
        searchType
    )

    const handleInputChange = useCallback(
        (e: React.SyntheticEvent, inputValue: string, reason: string) => {
            if (reason === 'reset') {
                setQuery('')
            } else {
                setQuery(inputValue)
            }
        },
        [setQuery]
    )

    const onBlur = useCallback<React.FocusEventHandler<HTMLDivElement>>(
        (e) => {
            onChange(e, [query] as SearchInputValue<Multiple, AllowCreation>, 'blur')
            setQuery('')
        },
        [onChange, query]
    )

    const searchResultsFiltered = useMemo(() => {
        let filteredResults = searchResults
        if (multiple && Array.isArray(value) && value.length > 0) {
            const selectedResults = value as SearchResultType<false>[]
            const keysToExclude = new Set(selectedResults.map((d) => d.id))
            if (keysToExclude.size > 0) {
                filteredResults = searchResults?.filter((d) => !keysToExclude.has(d.id))
            }
        }
        return filteredResults || []
    }, [searchResults, value, multiple])

    const loading = isInitialLoading && isLoading && !searchResults
    const characterLimit = Math.floor(((inputRef.current?.clientWidth || 330) - 60) / 8.18)

    return (
        <Autocomplete<SearchResultType<AllowCreation>, Multiple, undefined, true>
            multiple={multiple}
            value={value}
            freeSolo
            sx={{
                width: {
                    xs: '100%',
                    sm: fullWidth ? '100%' : 330,
                },
                marginRight: { xs: '16px' },
            }}
            ListboxProps={{
                sx: {
                    '& .MuiListItem-root .MuiAutocomplete-option': { padding: 0, margin: 0 },
                    padding: 0,
                    margin: 0,
                },
            }}
            blurOnSelect={blurOnSelect}
            groupBy={types.length === 1 ? undefined : (option) => option.type}
            filterOptions={(x) => x}
            open={active}
            onOpen={() => setActive(true)}
            onClose={() => setActive(false)}
            onChange={onChange}
            onBlur={onBlur}
            onInputChange={handleInputChange}
            inputValue={query}
            disabled={disabled}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option
                }
                return option.title
            }}
            options={searchResultsFiltered}
            loading={loading}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        {...getTagProps({ index })}
                        key={option.id}
                        label={option.title}
                        disabled={
                            (option.urlSlug && disabledSlugs?.includes(option.urlSlug)) || disabled
                                ? true
                                : option.disabled
                        }
                    />
                ))
            }
            renderGroup={({ group, children }) => (
                <React.Fragment key={group}>
                    <ListSubheader sx={{ lineHeight: '30px', backgroundColor: 'background.default', padding: 0 }}>
                        <Box sx={{ marginLeft: 2 }}>
                            {group === 'agency' ? 'Agencies' : group === 'staff' ? 'Staff' : `${capitalize(group)}s`}
                        </Box>
                        <Divider />
                    </ListSubheader>
                    {children}
                </React.Fragment>
            )}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.title, inputValue, { insideWords: true })
                const parts = parse(option.title, matches)
                const isSubtitleWrapping = (option.subtitle?.length || 0) >= characterLimit
                const isDescriptionWrapping =
                    (option.subtitle?.length || 0) + (option.description?.length || 0) >= characterLimit
                return (
                    <React.Fragment key={option.id}>
                        <li {...props} key={option.id} style={{ padding: 0 }}>
                            <Stack width="100%" padding={0} margin={0}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        height: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: 0,
                                        margin: 0,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: 60,
                                            height: 45,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexShrink: 0,
                                            padding: 0,
                                            marginY: 0,
                                            marginX: 1,
                                        }}
                                    >
                                        <ImageWithFallback
                                            key={option.urlSlug}
                                            src={option.imageUrl}
                                            fallbackSize={38}
                                            alt="search result image"
                                            priority
                                            sizes="100%"
                                        >
                                            {option.type === 'team' ? <GroupsIcon /> : undefined}
                                        </ImageWithFallback>
                                    </Box>
                                    <Box sx={{ flexGrow: 1, padding: 0, margin: 0 }}>
                                        <Box>
                                            {parts.map((part, i) => (
                                                <Typography
                                                    key={`${option.urlSlug || option.title}-${i.toString()}`}
                                                    color="text.primary"
                                                    component="span"
                                                    sx={{
                                                        lineHeight: 1.5,
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </Typography>
                                            ))}
                                        </Box>
                                        {(!isSubtitleWrapping || !isDescriptionWrapping) && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                {!isSubtitleWrapping && (
                                                    <SearchResultTypographySecondary
                                                        text={option.subtitle}
                                                        characterLimit={characterLimit}
                                                    />
                                                )}
                                                {!isDescriptionWrapping &&
                                                    !!option.subtitle &&
                                                    !!option.description && (
                                                        <SearchResultTypographySecondary
                                                            marginX={0.5}
                                                            text="|"
                                                            characterLimit={characterLimit}
                                                        />
                                                    )}
                                                {!isDescriptionWrapping && (
                                                    <SearchResultTypographySecondary
                                                        text={option.description}
                                                        characterLimit={characterLimit}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        {isSubtitleWrapping && (
                                            <SearchResultTypographySecondary
                                                text={option.subtitle}
                                                ellipses
                                                width="100%"
                                                lineHeight={1.3}
                                                characterLimit={characterLimit}
                                            />
                                        )}
                                        {isDescriptionWrapping && (
                                            <SearchResultTypographySecondary
                                                text={option.description}
                                                ellipses
                                                width="100%"
                                                height="100%"
                                                lineHeight={1.3}
                                                alignItems="bottom"
                                                characterLimit={characterLimit}
                                            />
                                        )}
                                    </Box>
                                </Box>
                                <Divider />
                            </Stack>
                        </li>
                    </React.Fragment>
                )
            }}
            renderInput={(params) => {
                const selectHasValue = Array.isArray(value) ? !!value.length : !!value
                const inputProps = {
                    ...params.InputProps,
                    label,
                    required,
                    autoFocus,
                    placeholder,
                    style: {
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingRight: 3,
                    },
                    endAdornment: loading ? (
                        <CircularProgress sx={{ marginRight: 1 }} size={20} />
                    ) : (
                        query && (
                            <IconButton
                                onClick={() => {
                                    setActive(false)
                                    setQuery('')
                                }}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        )
                    ),
                }
                if (!selectHasValue) inputProps.startAdornment = <SearchIcon sx={{ color: 'grey.600' }} />

                return (
                    <TextField
                        {...params}
                        sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: error ? 'error.main' : '',
                            },
                            '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: error ? 'error.main' : '',
                            },
                            '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: error ? 'error.main' : '',
                            },
                        }}
                        InputProps={inputProps}
                        ref={inputRef}
                    />
                )
            }}
        />
    )
}
