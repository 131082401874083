import type { LinkProps as NextLinkProps } from 'next/link'
import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Link from './Link'

export type MenuItemLinkProps = {
    href: NextLinkProps['href']
    label: string
    authorize: boolean
    icon?: JSX.Element | undefined
    newTab?: boolean | undefined
    type?: string
}

const MenuItemLink = ({ href, label, authorize, icon, newTab }: MenuItemLinkProps): JSX.Element | null => {
    let options = {}
    if (newTab) options = { target: '_blank', rel: 'noopener noreferrer' }

    return authorize ? (
        <Link
            sx={{
                color: 'common.black',
                '&:hover': {
                    color: 'initial',
                },
                textDecoration: 'none',
            }}
            href={href}
            {...options}
        >
            <MenuItem sx={{ gap: 1 }}>
                {icon}
                {label}
            </MenuItem>
        </Link>
    ) : null
}

export default MenuItemLink
