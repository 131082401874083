import React from 'react'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/material/IconButton'
import SearchInput from './SearchInput'

type MobileSearchInputProps = {
    searchOpen: boolean
    handleSearch: () => void
}

const MobileSearchInput = ({ searchOpen, handleSearch }: MobileSearchInputProps): JSX.Element => (
    <Box
        sx={{
            display: { xs: searchOpen ? 'flex' : 'none', sm: 'none' },
            alignItems: 'center',
            zIndex: 2,
            bgcolor: 'common.white',
            boxShadow: 1,
            position: 'absolute',
            left: 0,
            right: 0,
            gap: 1,
        }}
    >
        <IconButton
            size="large"
            aria-label="search"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleSearch}
            sx={{ display: { sm: 'none' } }}
            edge="end"
        >
            <ArrowBackIcon sx={{ color: 'common.black' }} />
        </IconButton>
        <SearchInput autoFocus />
    </Box>
)

export default MobileSearchInput
