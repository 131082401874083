import type { SystemCssProperties } from '@mui/system'
import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

type ControlsProps = {
    children: React.ReactElement[] | React.ReactElement
    horizontal?: boolean
    disabled?: boolean
    padding?: string
    sx?: SystemCssProperties
}

const Controls = ({ children, horizontal, disabled, padding, sx }: ControlsProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    return (
        <>
            <IconButton
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{ marginLeft: 'auto', padding, ...sx }}
                size="medium"
                aria-label="controls"
                disabled={disabled}
            >
                {horizontal ? <MoreHorizIcon fontSize="inherit" /> : <MoreVertIcon fontSize="inherit" />}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClick={() => setAnchorEl(null)}
                onClose={() => setAnchorEl(null)}
            >
                {children}
            </Menu>
        </>
    )
}

export default Controls
