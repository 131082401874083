import type { Except } from 'type-fest'
import type { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

type RiseLogoProps = Except<SvgIconProps, 'fontSize' | 'viewBox'> & {
    fontSize?: number | 'small' | 'medium' | 'large'
}

const logoViewBoxHeight = 121.19
const logoViewBoxWidth = 225.02
const RiseLogo = ({ fontSize, sx, ...props }: RiseLogoProps): JSX.Element => (
    <SvgIcon
        viewBox={`0 0 ${logoViewBoxWidth} ${logoViewBoxHeight}`}
        fontSize={typeof fontSize === 'number' ? undefined : fontSize}
        sx={{
            width: `${logoViewBoxWidth / logoViewBoxHeight}em`,
            fontSize: typeof fontSize === 'number' ? fontSize : undefined,
            color: '#1e1e1e',
            ...sx,
        }}
        {...props}
    >
        <path d="M119.5,6.34l-15.73.2a4.68,4.68,0,0,0-4.61,4.74l1.27,101.44a2.17,2.17,0,0,0,2.17,2.13h20.74a2.17,2.17,0,0,0,2.17-2.2L124.23,11A4.67,4.67,0,0,0,119.5,6.34Z" />
        <path d="M88.05,7.75l-11,3.14a1.87,1.87,0,0,1-.4.07,4.43,4.43,0,0,0-4.24,4.48l1.42,97.15a2.29,2.29,0,0,0,2.29,2.26H92.46a2.3,2.3,0,0,0,2.3-2.34L93.26,11.73A4.49,4.49,0,0,0,88.7,7.66,2,2,0,0,0,88.05,7.75Z" />
        <path d="M66.74,14A4.71,4.71,0,0,0,62,13.77L51.73,18.85c-2.3,1.18-4.36,2.81-4.25,4.78l1,89a2.25,2.25,0,0,0,2.25,2.23H67.2a2.25,2.25,0,0,0,2.26-2.28c-.27-23.13-1.2-96-1.2-96A3.37,3.37,0,0,0,66.74,14Z" />
        <path d="M42.91,21.58c-.67-.71-1.4-.57-2.31-.21,0,0-4.91,2.16-5,2.2-1.58.92-2.12,1.41-2.05,3l1,86.63a1.69,1.69,0,0,0,1.7,1.67H43a1.69,1.69,0,0,0,1.7-1.72L43.65,23.59A3.12,3.12,0,0,0,42.91,21.58Z" />
        <path d="M29.9,27.39a3.65,3.65,0,0,0-.52-1.9c-.46-.67-.95-.54-1.57-.21,0,0-3.34,2-3.38,2.06A2.76,2.76,0,0,0,23,30.13L23.57,67l.32,46.7A1.13,1.13,0,0,0,25,114.85h4.65a1.12,1.12,0,0,0,1.13-1.14l-.34-46.77Z" />
        <path d="M19.54,31.52a4.88,4.88,0,0,0-.31-1.71c-.28-.61-.57-.49-.93-.19l-2,1.83a3,3,0,0,0-.8,2.51L16,67.11l.3,46.76a1,1,0,0,0,1,1h2.08a1,1,0,0,0,1-1L20,67.06Z" />
        <path d="M137,7.75l11,3.14a1.85,1.85,0,0,0,.41.07,4.43,4.43,0,0,1,4.23,4.48l-1.41,97.15a2.3,2.3,0,0,1-2.3,2.26H132.56a2.3,2.3,0,0,1-2.3-2.34l1.5-100.78a4.49,4.49,0,0,1,4.56-4.07A2,2,0,0,1,137,7.75Z" />
        <path d="M158.28,14a4.71,4.71,0,0,1,4.76-.24l10.25,5.08c2.3,1.18,4.37,2.81,4.25,4.78l-1,89a2.26,2.26,0,0,1-2.26,2.23H157.82a2.26,2.26,0,0,1-2.26-2.28c.28-23.13,1.2-96,1.2-96A3.4,3.4,0,0,1,158.28,14Z" />
        <path d="M182.12,21.58c.67-.71,1.39-.57,2.31-.21,0,0,4.9,2.16,5,2.2,1.59.92,2.12,1.41,2.06,3l-1,86.63a1.7,1.7,0,0,1-1.7,1.67H182a1.7,1.7,0,0,1-1.7-1.72l1.09-89.54A3.07,3.07,0,0,1,182.12,21.58Z" />
        <path d="M195.12,27.39a3.74,3.74,0,0,1,.52-1.9c.46-.67,1-.54,1.57-.21l3.39,2.06A2.77,2.77,0,0,1,202,30.13L201.46,67l-.32,46.7a1.13,1.13,0,0,1-1.13,1.12h-4.66a1.12,1.12,0,0,1-1.13-1.14l.34-46.77Z" />
        <path d="M205.48,31.52a4.88,4.88,0,0,1,.31-1.71c.28-.61.57-.49.93-.19l2,1.83a3,3,0,0,1,.81,2.51l-.46,33.15-.3,46.76a1,1,0,0,1-1,1h-2.08a1,1,0,0,1-1-1L205,67.06Z" />
    </SvgIcon>
)

export default RiseLogo
