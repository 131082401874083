import type { Except } from 'type-fest'
import type { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

type RiseLogoWithTextProps = Except<SvgIconProps, 'fontSize' | 'viewBox'> & {
    fontSize?: number | 'small' | 'medium' | 'large'
}

const logoViewBoxHeight = 113.15
const logoViewBoxWidth = 144.63
const RiseLogoWithText = ({ fontSize, sx, ...props }: RiseLogoWithTextProps): JSX.Element => (
    <SvgIcon
        viewBox={`35.69 25.21 ${logoViewBoxWidth} ${logoViewBoxHeight}`}
        fontSize={typeof fontSize === 'number' ? undefined : fontSize}
        sx={{
            width: `${logoViewBoxWidth / logoViewBoxHeight}em`,
            fontSize: typeof fontSize === 'number' ? fontSize : undefined,
            color: '#1e1e1e',
            ...sx,
        }}
        {...props}
    >
        <path d="M113.21,25.21l-11.72.15a3.48,3.48,0,0,0-3.44,3.53l1,75.6a1.61,1.61,0,0,0,1.61,1.6h15.46a1.62,1.62,0,0,0,1.62-1.64l-1-75.8A3.48,3.48,0,0,0,113.21,25.21Z" />
        <path d="M89.77,26.26,81.59,28.6a1.45,1.45,0,0,1-.3,0A3.31,3.31,0,0,0,78.13,32l1.06,72.41a1.72,1.72,0,0,0,1.71,1.69H93.06a1.71,1.71,0,0,0,1.71-1.75L93.65,29.22a3.35,3.35,0,0,0-3.4-3A2,2,0,0,0,89.77,26.26Z" />
        <path d="M73.89,30.93a3.5,3.5,0,0,0-3.55-.18L62.7,34.53c-1.71.88-3.25,2.1-3.17,3.56l.74,66.34A1.68,1.68,0,0,0,62,106.09H74.23a1.68,1.68,0,0,0,1.68-1.7C75.71,87.15,75,32.81,75,32.81A2.5,2.5,0,0,0,73.89,30.93Z" />
        <path d="M56.12,36.57c-.5-.53-1-.43-1.72-.16l-3.7,1.64c-1.18.69-1.58,1.06-1.53,2.22l.76,64.57a1.27,1.27,0,0,0,1.27,1.25h5a1.27,1.27,0,0,0,1.27-1.29l-.81-66.73A2.35,2.35,0,0,0,56.12,36.57Z" />
        <path d="M46.43,40.9A2.68,2.68,0,0,0,46,39.48c-.34-.5-.71-.4-1.17-.15,0,0-2.49,1.5-2.52,1.53a2.09,2.09,0,0,0-1,2.08l.4,27.5L42,105.25a.85.85,0,0,0,.84.84h3.47a.84.84,0,0,0,.84-.86l-.25-34.85Z" />
        <path d="M38.71,44a3.63,3.63,0,0,0-.24-1.27c-.2-.45-.42-.36-.69-.14,0,0-1.45,1.34-1.47,1.37a2.21,2.21,0,0,0-.6,1.86l.34,24.72.22,34.84a.75.75,0,0,0,.74.74h1.56a.75.75,0,0,0,.74-.75l-.24-34.87Z" />
        <path d="M126.23,26.26l8.18,2.34a1.45,1.45,0,0,0,.3,0A3.31,3.31,0,0,1,137.87,32l-1.06,72.41a1.72,1.72,0,0,1-1.71,1.69H122.94a1.71,1.71,0,0,1-1.71-1.75l1.12-75.12a3.35,3.35,0,0,1,3.4-3A2,2,0,0,1,126.23,26.26Z" />
        <path d="M142.11,30.93a3.5,3.5,0,0,1,3.55-.18l7.64,3.78c1.71.88,3.25,2.1,3.17,3.56l-.74,66.34a1.68,1.68,0,0,1-1.68,1.66H141.77a1.68,1.68,0,0,1-1.68-1.7c.2-17.24.89-71.58.89-71.58A2.5,2.5,0,0,1,142.11,30.93Z" />
        <path d="M159.88,36.57c.5-.53,1-.43,1.72-.16l3.7,1.64c1.18.69,1.58,1.06,1.53,2.22l-.76,64.57a1.27,1.27,0,0,1-1.27,1.25h-5a1.27,1.27,0,0,1-1.27-1.29l.81-66.73A2.35,2.35,0,0,1,159.88,36.57Z" />
        <path d="M169.57,40.9a2.68,2.68,0,0,1,.39-1.42c.34-.5.71-.4,1.17-.15,0,0,2.49,1.5,2.52,1.53a2.09,2.09,0,0,1,1,2.08l-.4,27.5-.24,34.81a.85.85,0,0,1-.84.84h-3.47a.84.84,0,0,1-.84-.86l.25-34.85Z" />
        <path d="M177.29,44a3.63,3.63,0,0,1,.24-1.27c.2-.45.42-.36.69-.14,0,0,1.45,1.34,1.47,1.37a2.21,2.21,0,0,1,.6,1.86L180,70.51l-.22,34.84a.75.75,0,0,1-.74.74h-1.56a.75.75,0,0,1-.74-.75l.24-34.87Z" />
        <path d="M49.29,128.45H42.47V138H38.15V114.14H48.9a10.23,10.23,0,0,1,6.65,1.93c1.56,1.29,2.34,2.16,2.34,4.6a7,7,0,0,1-1.21,4.18,7.64,7.64,0,0,1-3.35,2.59l1.34,1.31A5.84,5.84,0,0,1,56,130.69l2.83,7.55-4.53,0-1.39-4.11a13.85,13.85,0,0,0-2.62-4.6ZM42.47,125h6.45a4.91,4.91,0,0,0,3.41-1.11,3.83,3.83,0,0,0,1.23-3c0-1.34-.37-1.37-1.13-2.09A4.82,4.82,0,0,0,49,117.63H42.47Z" />
        <path d="M88.77,138.29H84.46V113.41h4.31Z" />
        <path d="M129,131.54a3,3,0,0,0-1.15-2.53,13.92,13.92,0,0,0-4.16-1.79,20.42,20.42,0,0,1-4.79-2,6.37,6.37,0,0,1-3.4-5.57,6.08,6.08,0,0,1,2.45-5,9.94,9.94,0,0,1,6.37-2,10.71,10.71,0,0,1,4.63,1,7.64,7.64,0,0,1,3.2,2.73,7,7,0,0,1,1.16,3.92H129a3.9,3.9,0,0,0-1.22-3,5,5,0,0,0-3.49-1.1,5.28,5.28,0,0,0-3.29.9,3,3,0,0,0-1.17,2.53,2.73,2.73,0,0,0,1.26,2.28,14.32,14.32,0,0,0,4.17,1.78,19.25,19.25,0,0,1,4.67,2,7.36,7.36,0,0,1,2.58,2.53,6.64,6.64,0,0,1,.82,3.34,5.91,5.91,0,0,1-2.39,4.94,10.3,10.3,0,0,1-6.47,1.84,12.08,12.08,0,0,1-5-1,8.25,8.25,0,0,1-3.52-2.76,6.85,6.85,0,0,1-1.26-4.1H119a4,4,0,0,0,1.4,3.28,6.09,6.09,0,0,0,4,1.16,5.35,5.35,0,0,0,3.4-.91A3,3,0,0,0,129,131.54Z" />
        <path d="M176.13,127.18H163.91v7.66h13.94v3.45H159.58V113.41h18.15v3.48H163.91v6.87h12.22Z" />
    </SvgIcon>
)

export default RiseLogoWithText
